<template>
  <div>
    <profile-videos v-if="actor" :actor="actor"> </profile-videos>
  </div>
</template>
<script>
import ProfileVideos from "../../components/ProfileVideos.vue";
import { mapGetters } from "vuex";
export default {
  name: "Videos",
  components: { ProfileVideos },
  computed: {
    ...mapGetters("actors", ["actor"]),
  },
};
</script>
<style lang="scss" scoped></style>
