<template>
  <Card class="main-card mt-5 px-2 md:px-4">
    <template #content>
      <div class="line">
        <div><h2 class="mt-0">Filmy</h2></div>
      </div>
      <p v-if="!videos">Brak filmów</p>
      <div v-if="videos" class="grid mt-2">
        <div
          v-for="(video, index) of videos"
          class="col-12 sm:col-12 md:col-6 lg:col-6"
          :key="index"
        >
          <iframe
            id="ytplayer"
            type="text/html"
            width="100%"
            height="240"
            :src="video"
            frameborder="0"
          />
        </div>
      </div>
      <div v-if="actor.polish_movie">
        <h2 class="mt-0">Filmpolski.pl</h2>
        <div class="flex mb-4">
          <strong class="mr-2">Link: </strong>
          <a :href="actor.polish_movie" target="_blank">{{
            actor.polish_movie
          }}</a>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import {
  youtube_parser,
  vimeo_parser,
  google_drive_parser,
} from "../helpers/movieUtils.js";

export default {
  name: "ProfileVideos",
  props: ["actor"],
  data() {
    return {
      videos: [],
    };
  },
  methods: {
    videoMapper(video) {
      const isVimeo = video.includes("vimeo");
      const isGoogle = video.includes("drive");
      if (isGoogle) {
        return google_drive_parser(video);
      } else if (isVimeo) {
        return vimeo_parser(video);
      } else {
        const videoId = youtube_parser(video);
        return `https://www.youtube.com/embed/${videoId}?origin=${window.location.origin}`;
      }
    },
  },
  created() {
    const videoTypes = [
      "business_card_link",
      "demo_link",
      "monologue_advertisement_link",
      "archival",
      "additional_field",
      "second_additional_field",
    ];
    videoTypes.forEach((type) => {
      if (this.actor[type]) {
        const videoUrl = this.videoMapper(this.actor[type]);
        this.videos.push(videoUrl);
      }
    });
    if (this.actor.video_links?.length > 0) {
      const videoUrls = this.actor.video_links.map((video) =>
        this.videoMapper(video)
      );
      this.videos.push(...videoUrls);
    }
  },
};
</script>
<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main";
@import "../styles/main_page";
</style>
