export const youtube_parser = (url) => {
  const regExpDotted = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\???=?([^#&?]*).*/;
  const regExpDefault = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.startsWith("https://www.youtube.com")
    ? url.match(regExpDefault)
    : url.match(regExpDotted);
  return match && match[8]?.length === 11 ? match[8] : false;
};

export const vimeo_parser = (url) => {
  const vimeoEmbedUrl = "https://player.vimeo.com/video/";
  const urlSplitted = url.split("/");

  return vimeoEmbedUrl + urlSplitted.pop();
};
//https://drive.google.com/file/d/1mC3rswiS_ezGR3jbonik2PWuWi4bbpWc/view?usp=share_link
export const google_drive_parser = (url) => {
  const urlCut = url.split("?")[0];

  return urlCut.replace("/view", "/preview");
};
